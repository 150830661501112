<div>
  <!-- Header -->
  <div class="history-modal-banner">
    <div class="icon close clickable" (click)="onCancel()"></div>
    <h2 class="modal-title">{{ modalTitle }}</h2>
  </div>
  <!-- tables -->
  <tasks-notes-activity-table
    [currentUser]="currentUser"
    [currentSchool]="currentSchool"
    [partnerType]="partnerType"
    [studentData]="studentData"
    [segmentedControlIndex]="segmentedControlIndex"
    [listType]="listType"
    [calcValueField]="calcValueField"
    [contextPartnerId]="contextPartnerId"
    [userSelectedFilters]="userSelectedFilters"
    (updateIndex)="updateModalTitle($event)"
    (saveStudent)="saveStudent.emit($event)"
  >
  </tasks-notes-activity-table>
</div>
