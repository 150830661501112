import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../../../shared/services/api-service/api-service';
import { IGridFilter } from '../../../../shared/typings/interfaces/grid.interface';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ServerSideGridService } from 'Src/ng2/school/server-side-grid/services/server-side-grid.service';

export interface IMockRegentsRedirectInfo {
  filterDetails?: string[];
  filters?: IGridFilter[];
}

/* eslint-disable no-unused-vars */
export enum MockRegentsViewType {
  AREA_STANDARD = 'areastandard',
  STUDENT = 'student',
  QUESTIONS = 'questions',
  NONE = '',
}

export const MockRegentsSubViewType = {
  FILTERED_STUDENTS: 'filtered_students',
  NONE: '',
};

export const MockRegentsViewTypeOptions = [
  { key: MockRegentsViewType.AREA_STANDARD, human: 'Area/Standard Analysis' },
  { key: MockRegentsViewType.STUDENT, human: 'Student Results' },
  { key: MockRegentsViewType.QUESTIONS, human: 'Question Analysis' },
];

export const MockRegentsItemizedDataColumnFields = [
  'student_id',
  'student_name',
  'exam_name',
  'scale_score',
  'scale_score_category',
  'level3_description',
  'level2_description',
  'level1_description',
  'question_label',
  'points_earned',
  'points_possible',
  'student_mc_answer',
  'mc_correct_answer',
  'prior_attempts_category',
  'aligned_course_status',
  'aligned_course_cd_section',
  'class_of',
  'grade',
  'ethnicity',
  'gender',
  'is_swd',
  'is_ml',
  'ell_status',
  'official_class',
  'percent_present',
  'nv_attendance_risk_group',
];

@Injectable()
export class MockRegentsDataService {
  constructor (
    private apiService: ApiService,
    private ssgService: ServerSideGridService,
  ) {}

  private _cachedColumnDefConfig: Array<ColDef>;

  public getGridConfig$ (viewType: MockRegentsViewType): Observable<Array<ColDef>> {
    const query = `{
      MockRegentsGridConfig (viewType: "${viewType}")  {
        field
        headerName
        headerTooltip
        filter
        filterParams
        width
        hide
        valueFormatter
        valueGetter
        pinned
        lockPinned
        checkboxSelection
        enableRowGroup
        rowGroup
        canGroup
        columnOrder
        sortable
        initialSort
        initialSortIndex
        aggFunc
        cellRenderer
        cellRendererParams
      }
    }`;

    const payload = { query, fetchPolicy: 'no-cache' };

    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => {
        const columnDefs = res.data.MockRegentsGridConfig;
        return columnDefs.map((col: ColDef) => this.ssgService.formatColumnDefinition(col));
      }),
      catchError(() => of([])),
    );
  }

  public getGridData$ ({ schoolId, viewType, mockExamName, mockExamDate, request, columnDefs, studentIds = [] }) {
    if (!mockExamName || mockExamName === '') return of([]);

    const aggs = viewType === MockRegentsViewType.STUDENT
      ? []
      : viewType === MockRegentsViewType.AREA_STANDARD
        ? [
            'question_label',
            'level1_description',
            'level2_description',
            'level3_description',
            'exam_name',
            'count_students_0_percent',
            'student_ids_0_percent',
            'student_ids_1_to_99_percent',
            'count_students_1_to_99_percent',
            'student_ids_100_percent',
            'count_students_100_percent',
          ]
        : viewType === MockRegentsViewType.QUESTIONS
          ? [
              'question_label',
              'area',
              'standard',
              'student_mc_answer',
              'mc_option_a',
              'student_ids_option_a',
              'mc_option_b',
              'student_ids_option_b',
              'mc_option_c',
              'student_ids_option_c',
              'mc_option_d',
              'student_ids_option_d',
              'mc_option_none',
              'student_ids_option_none',
              'mc_correct_answer',
            ]
          : [];
    let columnFields = columnDefs.map(({ field }) => field);
    columnFields = [...columnFields, ...aggs];
    const query = `
      query MockRegentsGrid($schoolId: String!, $viewType: String!, $mockExamName: String, $mockExamDate: String!, $agGridRequest: AgGridRequest!, $columnFields: [String!], $studentIds: [String]) {
        MockRegentsGridData(schoolId: $schoolId, viewType: $viewType, mockExamName: $mockExamName, mockExamDate: $mockExamDate, agGridRequest: $agGridRequest, columnFields: $columnFields, studentIds: $studentIds) {
          ${columnFields}
          count
        }
      }
  `;

    const payload = { query, fetchPolicy: 'no-cache', variables: { schoolId, viewType, mockExamName, mockExamDate, agGridRequest: request, columnFields, studentIds } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.MockRegentsGridData),
      catchError(() => of([])),
    );
  }

  public getGridCount$ ({ schoolId, viewType, mockExamName, mockExamDate, request, studentIds = [] }):Observable<{count: number}> {
    if (!mockExamName || mockExamName === '') return of({ count: 0 });

    const query = `
      query MockRegentsGrid($schoolId: String!, $viewType: String!, $mockExamName: String, $mockExamDate: String, $agGridRequest: AgGridRequest!, $studentIds: [String]) {
        MockRegentsGridCount(schoolId: $schoolId, viewType: $viewType, mockExamName: $mockExamName, mockExamDate: $mockExamDate, agGridRequest: $agGridRequest, studentIds: $studentIds) {
          count
        }
      }
    `;
      // total or filtered
    const payload = { query, fetchPolicy: 'no-cache', variables: { schoolId, viewType, mockExamName, mockExamDate, agGridRequest: request, studentIds } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.MockRegentsGridCount),
      catchError(() => of([])),
    );
  }

  public getMockRegentsViewTypes$ (): Observable<any[]> {
    return of(MockRegentsViewTypeOptions);
  }

  public getMockRegentsExams$ ({ schoolId }):Observable<{count: number}> {
    const query = ` {
      MockRegentsExams(schoolId:"${schoolId}"){
          examName
      }
    }`;
    // total or filtered
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.MockRegentsExams),
      catchError(() => of([])),
    );
  }

  public getMockRegentsItemLevelData$ ({ schoolId, mockExamName, mockExamDate, request }): Observable<any[]> {
    const query = `
      query MockRegentsItemData($schoolId: String!, $mockExamName: String!, $mockExamDate: String!, $agGridRequest: AgGridRequest!, $columnFields: [String!]) {
        MockRegentsItemData(schoolId: $schoolId, mockExamName: $mockExamName, mockExamDate: $mockExamDate, agGridRequest: $agGridRequest, columnFields: $columnFields) {
          ${MockRegentsItemizedDataColumnFields}
        }
      }
    `;

    const payload = { query, fetchPolicy: 'no-cache', variables: { schoolId, mockExamName, mockExamDate, agGridRequest: request, MockRegentsItemizedDataColumnFields } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => {
        const data = res.data.MockRegentsItemData;
        // Replace null values with empty string
        const sanitizedData = data.map((row) => {
          const sanitizedRow = {};
          for (const key in row) {
            if (key === 'student_mc_answer' || key === 'mc_correct_answer') {
              sanitizedRow[key] = this.convertAnswerValue(row[key]);
            } else {
              sanitizedRow[key] = row[key] !== null ? row[key] : '';
            }
          }
          return sanitizedRow;
        });
        return sanitizedData;
      }),
      catchError(() => of([])),
    );
  }

  private convertAnswerValue (value: number): string {
    return ['A', 'B', 'C', 'D', ''][value - 1] || '';
  }

  public getConfigs$ ({ schoolId }):Observable<{groupOptions: any[], levelOptions: any[]}> {
    const query = ` {
      MockRegentsConfigs(schoolId: "${schoolId}") {
        groupOptions {
          field
          header_name
          is_active
        },
        levelOptions { 
          field
          header_name
          column_order
        }
      }
    }
    `;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.MockRegentsConfigs),
      catchError(() => of([])),
    );
  }

  public getFilter$ ({ schoolId }):Observable<{ filterOptions: any[]}> {
    const query = ` {
      MockRegentsFilters(schoolId: "${schoolId}") { 
        key
        human
        options
        exam_name
      }
    }
    `;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.MockRegentsFilters),
      catchError(() => of([])),
    );
  }

  public findParentFilterOption (optionKey: string, filterOptions): IDropdownOption | undefined {
    // Find the parent option of this.selectedFilterOption
    let parentOption: IDropdownOption | undefined;
    for (const option of filterOptions) {
      if (option?.options?.some(opt => opt.key === optionKey)) {
        parentOption = option;
        break;
      }
    }
    return parentOption;
  }

  public getFilterMappedValue (column: string): string {
    const valueMappings = {
      // eslint-disable-next-line quote-props
      'class': 'class_of',
      'official class': 'official_class',
      'aligned course status': 'aligned_course_status',
      'aligned course section': 'aligned_course_cd_section',
      'prior attempts': 'prior_attempts_category',
      'attendance band': 'nv_attendance_risk_group',
      'ml status': 'is_ml',
      'swd status': 'is_swd',
      'ell status': 'ell_status',
      'all students': '',
    };
    return valueMappings[column] || column;
  }

  public getTextFilterColumns (): string[] {
    return [
      'official_class',
      'aligned_course_cd_section',
    ];
  }
}
