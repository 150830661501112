import { FocusableOption } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { ISidebarItemQueryParams } from '../nv-sidebar-list/nv-sidebar.interface';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';

/**
 * Used internally by the Sidebar List.
 *
 * Like the Dropdown Item, it's not likely that you'll use this component on its own.
 *
 * V3 to V4 Notes:
 *
 * There are significant enough differences between the default and selected
 * icons in V4 that we cannot just simply use color to change
 * the color of the icons
 */
@Component({
  selector: 'nv-sidebar-item',
  templateUrl: './nv-sidebar-item.component.html',
  styleUrls: ['./nv-sidebar-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvSidebarItemComponent implements FocusableOption {
  /**
   *  Label for the Side Bar Item
   */
  @Input() label: string;

  /**
   *  URL redirect link
   */

  @Input() url?: string;

  /**
   * Passes the query params to the url that the side bar item redirects to.
  */

  @Input() queryParams?: ISidebarItemQueryParams;

  /**
   *  Used to apply selected icons and styling
   */
  @Input() isSelected: boolean = false;

  /**
   *  Gives child class if the current sidebar item has a parent
   */
  @Input() isChild: boolean = false;

  /**
   *  Disables sidebar item
   */
  @Input() isDisabled: boolean = false;

  /**
   * Specifies what the icon on the left should be when in the default state
   */

  @Input() leftDefaultIcon: string;

  /**
   * Specifies what the icon on the left should be when in the selected state
   */

  @Input() leftSelectedIcon: string;

  /**
  * The visually hidden ARIA label for describing left default icon purpose.
  */

  @Input() leftDefaultIconAriaLabel: string = null;

  /**
  * The visually hidden ARIA label for describing left selected icon purpose.
  */

  @Input() leftSelectedIconAriaLabel: string = null;

  /**
   * Specifies what the icon on the right should be when in the default state
   */

  @Input() rightDefaultIcon: string;

  /**
   * Specifies what the icon on the right should be when in the selected state
   */

  @Input() rightSelectedIcon: string;

  /**
   * Specifies what the icon on the right should be when in the hover state
   */

  @Input() rightHoverIcon: string;

  /**
   * Specifies the menu options for the rightHoverIcon
   */

  @Input() rightHoverIconOptions: IDropdownOption[];
  /**
  * The visually hidden ARIA label for describing right default icon purpose.
  */

  @Input() rightDefaultIconAriaLabel: string = null;

  /**
  * The visually hidden ARIA label for describing right selected icon purpose.
  */

  @Input() rightSelectedIconAriaLabel: string = null;

  /**
   * TEMPORARY ============== TODO: REMOVE once we have beta icon
   * Whether or not to display the beta flag next to the sidebar item
   */
  @Input() hasBetaFlag: boolean = false;
  /**
   * TEMPORARY ============== TODO: REMOVE once CAREER_DIRECTORY is no longer used
   */
  @Input() isExternalUrl: boolean = false;

  public v4Toggle: boolean;

  @Output() sidebarItemSelect: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconMenuSelect: EventEmitter<string> = new EventEmitter<string>();

  public handleClick (_: any): void {
    this.sidebarItemSelect.emit();
  }

  public handleSelectSubOption ($event: { key: string }): void {
    this.iconMenuSelect.emit($event.key);
  }

  constructor (
    private element: ElementRef<HTMLElement>,
    private toggleService: ToggleService,
  ) {}

  public focus (): void {
    const host:HTMLElement = this.element.nativeElement;
    host.querySelector('button').focus();
  }

  public getBetaMessage () {
    const isSummerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
    const BETA = 'beta';
    const NEW = 'new';
    if (isSummerSchoolToggleOn) {
      return NEW;
    }
    return BETA;
  }
}
