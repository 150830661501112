import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { memoized } from 'Src/ng2/shared/helpers/memoized-decorator/memoized.service';
import { CodeDeckCharter } from 'Src/ng2/shared/constants/code-deck-charter.constant';
import { CodeDeckDistrict, ICodeDeck } from 'Src/ng2/shared/constants/code-deck-district.constant';
import { CodeDeckSummer } from 'Src/ng2/shared/constants/code-deck-summer.constant';
import { Cohort } from 'Src/ng2/shared/constants/cohort.constant';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { GraduationDate, NextGraduationDate } from 'Src/ng2/shared/constants/graduation-date.constant';
import { PlannedDiplomaType } from 'Src/ng2/shared/constants/planned-diploma-type.constant';
import { RegentsSubject } from 'Src/ng2/shared/constants/regents.constant';
import { SubjectAreas } from 'Src/ng2/shared/constants/subject-areas.constant';
import { TermsToGradMetrics } from 'Src/ng2/shared/constants/terms-to-grad-metrics.constant';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import {
  AIM_SCHOOLS_DBNS,
  ELEMENTARY_SCHOOL_TYPES,
  EMS_SCHOOL_TYPES,
  HIGH_SCHOOL_9_TO_12_TYPES,
  HIGH_SCHOOL_TYPES,
  MIDDLE_SCHOOL_TYPES,
} from '../../constants/school/school-types.constant';
import { Toggles } from '../../constants/toggles.constant';
import {
  ICourse,
  ICoursePartial,
  ISchool,
  ISummerSchool,
  TGrid,
  TValidSchoolTypes,
  TValidSchoolTypeCats,
} from '../../typings/interfaces/school.interface';
import { TValidPlannedDiplomaTypes } from '../../typings/interfaces/student.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { ToggleService } from '../toggle/toggle.service';
import { UserRolePermissionsForModelService } from '../user-role-permissions-for-model/user-role-permissions-for-model.service';
import { GRAD_PLAN_THRESHOLDS_MIN_VALUES } from '../../constants/graduation-plan.constant';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';

const SCHOOL_IS_MUTABLE = (arg, i) => i === 0 && arg && arg._mutable;
const PERMISSIONING_MODEL_NAME = 'IM_SCHOOL';

@Injectable()
export class ImSchool {
  permissioningFns;

  constructor (
    private UserRolePermissionsForModelService: UserRolePermissionsForModelService,
    private UtilitiesService: UtilitiesService,
    private toggleService: ToggleService,
  ) {
    this.permissioningFns = {
      canEdit: this.UserRolePermissionsForModelService.canEditPartial(PERMISSIONING_MODEL_NAME),
      canView: this.UserRolePermissionsForModelService.canViewPartial(PERMISSIONING_MODEL_NAME),
    };
  }

  getGradPlanCreditOpportunitiesMinValues (school: ISchool): ISchool['onTrackMetrics']['gradPlanThresholdsNew']['creditOpportunities'] | null {
    const { termStructure, district } = school;
    const creditMinValues = GRAD_PLAN_THRESHOLDS_MIN_VALUES[district]?.CREDITS;
    return (creditMinValues && creditMinValues[termStructure]) || null;
  }

  getGradPlanRegentsBenchmarksMinValues (school: ISchool): ISchool['onTrackMetrics']['gradPlanThresholdsNew']['regentsBenchmarks'] | null {
    const { district } = school;
    const regentsMinValues = GRAD_PLAN_THRESHOLDS_MIN_VALUES[district]?.REGENTS;
    return regentsMinValues || null;
  }

  // Used for gradPlanning SDC - returns thresholds used to calculate grad plans for students
  // school and cohort are required
  // asOfMonth is optional
  getGradPlanningThresholdsForCohort (school: ISchool, cohort, asOfMonth?) {
    let gradPlanningThresholdsForCohort;
    const gradPlanningThresholds = school.onTrackMetrics.gradPlanningThresholds;
    const cohortDetails = _.find(Cohort, { humanName: cohort });
    const gradPlanningThresholdsKeyForCohort = cohortDetails.keyForGradPlanningThresholds;
    gradPlanningThresholdsForCohort = gradPlanningThresholds[gradPlanningThresholdsKeyForCohort];

    if (asOfMonth) {
      gradPlanningThresholdsForCohort = gradPlanningThresholdsForCohort[asOfMonth];
    }
    return gradPlanningThresholdsForCohort;
  }

  // Used for gradPlanning metrics page - returns sentences that describe graduation
  // thresholds for students in a specific cohort going for a specific diploma type
  // school, cohort, asOfMonth, and plannedDiplomaType
  // plannedDiplomaType should be humanName (ie. "Advanced Regents")
  // gradMonth is optional and should be one of the values in GraduationMonth constant (ie. "June")
  getGradPlanningThresholdsExplainer (
    school: ISchool,
    cohort,
    asOfMonth,
    plannedDiplomaType: TValidPlannedDiplomaTypes,
    gradMonth?,
  ) {
    let gradPlanningThresholdsExplainer;
    const validPlannedDiplomaTypes = [
      PlannedDiplomaType.ADVANCED_REGENTS.humanName,
      PlannedDiplomaType.REGENTS.humanName,
      PlannedDiplomaType.LOCAL.humanName,
      PlannedDiplomaType.NON_GRADUATE.humanName,
    ];

    // Don't make a metrics explainer for No Plan planned diploma types
    if (!_.includes(validPlannedDiplomaTypes, plannedDiplomaType)) {
      throw new Error('Only valid for Advanced Regents, Regents, Local, and Non-Grad diploma types');
    }

    const plannedDiplomaTypeDetails = _.find(PlannedDiplomaType, { humanName: plannedDiplomaType });
    const plannedDiplomaTypeKey = plannedDiplomaTypeDetails.type;

    // asOf argument must be valid
    const asOfMonthString = this.getAsofMonthString(asOfMonth);

    if (!asOfMonthString) {
      throw new Error('Only valid for "asOfSept" or "asOfJanuary" values.');
    }

    const numberPassedString = plannedDiplomaTypeDetails.numberPassedString;
    const gradPlanningThresholdsForCohort = this.getGradPlanningThresholdsForCohort(school, cohort, asOfMonth);
    const juneRegentsThresholdForCohortAndDiplomaType = gradPlanningThresholdsForCohort.june[plannedDiplomaTypeKey];
    const juneCreditsThresholdForCohort = gradPlanningThresholdsForCohort.june.credits;
    const augRegentsThresholdForCohortAndDiplomaType = gradPlanningThresholdsForCohort.aug[plannedDiplomaTypeKey];
    const augCreditsThresholdForCohort = gradPlanningThresholdsForCohort.aug.credits;
    const plannedDiplomaTypePrefix = plannedDiplomaType === 'Advanced Regents' ? 'an' : 'a';
    const prefix = 'Active class of ' + cohort + ' students';

    // Explainer for invalid data input (JE)
    /* istanbul ignore if */
    if (
      augCreditsThresholdForCohort === null ||
      augCreditsThresholdForCohort === undefined ||
      juneCreditsThresholdForCohort === null ||
      juneCreditsThresholdForCohort === undefined
    ) {
      return `<b>One of the values you have entered in the metrics is invalid.</b>
        Please review these values to see an explanation for your metrics.`;
    }

    // Explainer for students not on target for a diploma this year
    /* istanbul ignore if */
    if (plannedDiplomaType === PlannedDiplomaType.NON_GRADUATE.humanName) {
      gradPlanningThresholdsExplainer =
        prefix +
        ' are <b>not considered on target for a diploma this year</b> if they have <b> fewer than ' +
        augCreditsThresholdForCohort +
        ' credits</b> ' +
        asOfMonthString +
        '.';
      return gradPlanningThresholdsExplainer;
    }

    // Explainer for students on target for a diploma this year
    const juneGradPlanningThresholdsExplainer =
      ' are considered on target for ' +
      plannedDiplomaTypePrefix +
      ' <b>' +
      plannedDiplomaType +
      ' Diploma in June</b> of their fourth year' +
      ' if they have <b>' +
      juneRegentsThresholdForCohortAndDiplomaType +
      '+ ' +
      numberPassedString +
      '</b> and <b>' +
      juneCreditsThresholdForCohort +
      '+ credits</b> ' +
      asOfMonthString +
      '.';

    const augGradPlanningThresholdsExplainer =
      ' are considered on target for ' +
      plannedDiplomaTypePrefix +
      ' <b>' +
      plannedDiplomaType +
      ' Diploma in August</b> of their fourth year' +
      ' if they have <b>' +
      augRegentsThresholdForCohortAndDiplomaType +
      '+ ' +
      numberPassedString +
      '</b> and <b>' +
      augCreditsThresholdForCohort +
      '+ credits</b> ' +
      asOfMonthString +
      '.';
    /* istanbul ignore if */
    if (gradMonth) {
      if (gradMonth === 'June') return prefix + juneGradPlanningThresholdsExplainer;
      if (gradMonth === 'Aug') return prefix + augGradPlanningThresholdsExplainer;
    }

    gradPlanningThresholdsExplainer =
      prefix + juneGradPlanningThresholdsExplainer + '  Students' + augGradPlanningThresholdsExplainer;

    return gradPlanningThresholdsExplainer;
  }

  getAsofMonthString (asOfMonth: string) {
    if (asOfMonth === 'asOfSept') return 'at the start of this school year';
    else if (asOfMonth === 'asOfJan') return 'after Jan Regents/credits are awarded';
    else return null;
  }

  getStaffOrgUnits (school: ISchool) {
    const self = school;
    return self.gafeDetails.staffOrgUnits;
  }

  // examName is camelcase with a lowercase first letter, eg "english"
  // returns full course objects
  getAlignedCoursesForRegentsExam (school: ISchool, examKey) {
    const alignedCourses = this.getAlignedCoursesForAllRegentsExams(school);
    const alignedCoursesForExam = _.filter(alignedCourses, { examKey });
    return alignedCoursesForExam || [];
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getAlignedCoursesForAllRegentsExams (school: ISchool) {
    /**
     *  The `allPrepCourses` value will look like:
     *    [
     *      { courseId: XXX, otherCourseProp, YYY, examKey: ccAlg },
     *      { courseId: XXX, otherCourseProp, YYY, examKey: ccGeom }
     *    ]
     */

    const masterSchedule = school.masterSchedule;
    const userEnteredPrepCourses = school.userEnteredPrepCourses;

    const allPrepCourses = _.reduce(
      masterSchedule,
      (result, course) => {
        // get the courseId of the course on the master
        const courseId = course.courseId;
        // get the static examSubject that we've assigned to this course during data processing
        const examSubject = course.coursePrepsFor;
        // if static examSubject is defined, create new array with examSubject
        const staticExamSubjectThisCoursePrepsFor = examSubject ? [examSubject] : [];
        // get the examSubjects the user says this course preps for
        const matchingPrepCourses = _.filter(userEnteredPrepCourses, { courseId });
        const editableExamSubjectsThisCoursePrepsFor = _.map(
          matchingPrepCourses,
          prepCourse => prepCourse.coursePrepsFor,
        );
        // build a master array that includes both static and editable course preps for
        const allExamSubjectsThisCoursePrepsFor = staticExamSubjectThisCoursePrepsFor.concat(
          editableExamSubjectsThisCoursePrepsFor,
        );
        // loop through each examSubjects this course preps for (i.e. ela, alg, etc.)
        // find the corresponding exams aligned to the examSubject (i.e. ccEla, compEla, ccAlg, oldAlg, etc.)
        _.each(allExamSubjectsThisCoursePrepsFor, examSubject => {
          const correspondingRegentsSubject = _.find(RegentsSubject, { key: examSubject });
          const correspondingRegentsExamsForRegentsSubject = correspondingRegentsSubject.exams;

          // loop through each exam aligned to this examSubject
          // push this course on master to allPrepCourses array
          _.each(correspondingRegentsExamsForRegentsSubject, exam => {
            const examKey = exam.key;
            const newCourse = this.UtilitiesService.copyPOJO(course);
            newCourse.examKey = examKey;
            result.push(newCourse);
          });
        });

        return result;
      },
      [],
    );

    return allPrepCourses;
  }

  /**
   * Groups `getAlignedCoursesForAllRegentsExams` by the string `${courseId}|${term}` for faster loop ups
   * @param school
   * @returns {object} keys being the concatenation `${courseId}|${term}`, values being a course
   */
  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getAlignedCoursesForAllRegentsExamsGroupByCourseIdTerm (school: ISchool) {
    const allPrepCourses = this.getAlignedCoursesForAllRegentsExams(school);
    return _.groupBy(allPrepCourses, course => `${course.courseId}`);
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getCurrentTermNumber (school: ISchool) {
    return school.currentTermNumber;
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getCurrentTermYear (school: ISchool) {
    return school.currentTermYear;
  }

  getEffectiveCurrentTermYear (school: ISchool) {
    const termYear = this.getCurrentTermYear(school);
    const effectiveCurrentTermYear = this.getEffectiveTermYear(school, termYear);

    return effectiveCurrentTermYear;
  }

  getEffectiveCourseTermYear (school: ISchool, course: ICoursePartial) {
    const { termYear } = course;
    const effectiveCurrentTermYear = this.getEffectiveTermYear(school, termYear);

    return effectiveCurrentTermYear;
  }

  private getEffectiveTermYear (school: ISchool, termYear: number): number {
    const { termStructure } = school;
    let effectiveTermYear = termYear;

    if (termStructure === 'ANNUALIZED') {
      const termYearEndsInTwo = `${termYear}`.endsWith('2');

      if (termYearEndsInTwo) effectiveTermYear--; // subtract one from term year if term year ends in two (e.g. 202 becomes 201)
    }

    return effectiveTermYear;
  }

  // when just the school is passed, immediate next term will be returned.
  // You also have the option to pass a term year to get the following term year (Devin)
  getNextTermYear (school: ISchool, termYear?: number) {
    // currentTermYear is an amalgamation of school year and the term.
    // eg: 171 (refers to year 2017-18 and term 1)
    let currentTermYear = termYear || school.currentTermYear;

    // A quick check to see if currentTermYear has at least 3 digits
    if (currentTermYear < 100) return;

    const currentTermNumber = termYear % 10 || school.currentTermNumber;

    // if the currentTermYear is 171, get the year portion i.e.: 17 (Devin)
    let yearPart = (currentTermYear - currentTermNumber) / 10;
    const totalTermsForSchool = this.getNumTermsPerYear(school);

    if (currentTermNumber < totalTermsForSchool) {
      return ++currentTermYear;
    } else if (currentTermNumber === totalTermsForSchool) {
      return yearPart * 10 + 7;
    } else if (currentTermNumber === 7) {
      return ++yearPart * 10 + 1;
    }
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getEndDateForCurrentTerm (school: ISchool) {
    const currentTermNumber = this.getCurrentTermNumber(school);
    // TODO: this is hack to get support end dates working for  'ANNUALIZED' schools
    // Further discussions required with data team to address issues pertaining to 'ANNUALIZED' schools `currentTermNumber` (CM)
    const termNumberDecrement = school.termStructure === 'ANNUALIZED' && currentTermNumber === 2 ? 2 : 1;
    const termNumberIndex = currentTermNumber - termNumberDecrement;
    const termEndDates = school.termEndDates;

    return termEndDates[termNumberIndex] || CurrentSchoolYear.LAST_DAY;
  }

  getSchoolCreditMetrics (school: ISchool, effectiveCohort) {
    const creditsExpectedByTerm = school.onTrackMetrics.creditsExpectedByTerm;
    const cohortString = effectiveCohort && `cohort_${effectiveCohort}`;
    const byTermCreditMetrics = cohortString ? creditsExpectedByTerm[cohortString] : null;
    const allCohortsCreditMetrics = school.onTrackMetrics.creditsExpectedByTerm.allCohorts;
    return this.UtilitiesService.copyPOJO(allCohortsCreditMetrics || byTermCreditMetrics);
  }

  // excludes summer!!!
  getNumTermsPerYear (school: ISchool) {
    const termStructure = school.termStructure;
    const numTermsPerYearByTermStructure = {
      // DS changed ANNUALIZED from 1 to 2 on 9/15/17
      // We want to treat ANNUALIZED schools as sememster schools for puposes of credit gaps
      ANNUALIZED: 2,
      SEMESTER: 2,
      TRIMESTER: 3,
      QUARTERLY: 4,
    };
    return numTermsPerYearByTermStructure[termStructure];
  }

  getNumTermsPerYearNoSpecialTreatment (school: ISchool) {
    const termStructure = school.termStructure;
    const numTermsPerYearByTermStructure = {
      ANNUALIZED: 1,
      SEMESTER: 2,
      TRIMESTER: 3,
      QUARTERLY: 4,
    };
    return numTermsPerYearByTermStructure[termStructure];
  }

  getGradReqForCourse (school: ISchool, courseId) {
    const self = school;
    const course = _.find(self.masterSchedule, function (course) {
      return course.courseId === courseId;
    });
    const ret = course ? course.gradReq : undefined;

    return ret;
  }

  getCreditValueForCourse (school: ISchool, courseId) {
    const self = school;
    const course = _.find(self.masterSchedule, function (course) {
      return course.courseId === courseId;
    });
    const ret = course ? course.creditValue : 0;

    return ret;
  }

  getCourseByIdFromMasterSchedule (school: ISchool, courseId: string): ICourse {
    const masterSchedule = school.masterSchedule;
    return _.find(masterSchedule, { courseId });
  }

  /**
   * finds all unique subject areas in school.masterSchedule and returns
   * the corresponding SubjectArea constant for each unique subject area
   * @param {Object} school
   * @return {Array} of SubjectArea objects
   */

  getUniqueSubjectAreasFromMasterSchedule (school: ISchool) {
    const masterSchedule = school.masterSchedule;
    const memo = {};

    return _.reduce(
      masterSchedule,
      (result, course) => {
        const courseSubjectArea = course.subjectArea;

        if (courseSubjectArea && !memo[courseSubjectArea]) {
          const subjectArea = _.find(SubjectAreas, { camelCase: courseSubjectArea });

          memo[courseSubjectArea] = true;
          result.push(subjectArea);
        }
        return result;
      },
      [],
    );
  }

  getSchoolCodeDeck (school: ISchool, termNumber?: number): ICodeDeck {
    let codeDeck;
    const { district } = school;
    termNumber = termNumber || this.getCurrentTermNumber(school);
    if (termNumber === 7) {
      // summer is always quarterly
      codeDeck = _.find(CodeDeckSummer, (v, k) => k === 'QUARTERLY');
    } else {
      const isCharter = this.isCharterSchool(school);
      if (isCharter) codeDeck = _.find(CodeDeckCharter, (v, k) => k === school.termStructure);
      else codeDeck = _.find(CodeDeckDistrict[district], (v, k) => k === school.termStructure);
    }
    return codeDeck;
  }

  isCharterSchool ({ isNVCMO }: ISchool): boolean {
    return isNVCMO;
  }

  hashMasterScheduleByTermYear (school: ISchool) {
    const masterSchedule = school.masterSchedule;
    return _.reduce(
      masterSchedule,
      function (result, course) {
        const termYear = course.termYear;
        if (!result[termYear]) result[termYear] = [];
        result[termYear].push(course);
        return result;
      },
      {},
    );
  }

  getMasterScheduleForTermYear (school: ISchool, termYear?) {
    termYear = termYear || this.getCurrentTermYear(school);
    const hashedMasterScheduleByTermYear = this.hashMasterScheduleByTermYear(school);
    const masterScheduleForTermYear = hashedMasterScheduleByTermYear[termYear] || [];
    return masterScheduleForTermYear;
  }

  getUpcomingGradDates () {
    const gradDateAsArray = _.toArray(GraduationDate);
    const nextGradDateIndex = _.indexOf(gradDateAsArray, NextGraduationDate);
    const nextFourGradDates = _.slice(gradDateAsArray, nextGradDateIndex);
    return nextFourGradDates;
  }

  // TRANSFER SCHOOL SPECIFIC
  isTransferSchool ({ schoolType }: ISchool) {
    return schoolType === 'Transfer';
  }

  getNextFourGradDatesForTransfer () {
    const upcomingGradDatesForTransfer = this.getUpcomingGradDates();
    const nextFourGradDates = _.slice(upcomingGradDatesForTransfer, 0, 4);
    return nextFourGradDates;
  }

  getDistanceToGradMetricsExplainer (school: ISchool, gradDate) {
    const next4Terms = this.getNextFourGradDatesForTransfer();
    const termsTilGrad = _.findIndex(next4Terms, { humanName: gradDate }) + 1; // compensates for 0-index
    const gradReqs = TermsToGradMetrics[termsTilGrad];
    const { regentsReq, creditReq } = gradReqs;
    const explainer =
      'According to the Distance from Graduation metric, students are' +
      ` on target to graduate in ${gradDate} if they have passed <b>${regentsReq}+ of 5` +
      ` Regents @ 65 or 55 and have earned ${creditReq}+ credits</b>.`;
    return explainer;
  }

  canEdit (school: ISchool, viewingUser) {
    return this.permissioningFns.canEdit(school, viewingUser);
  }

  canView (school: ISchool, viewingUser) {
    return this.permissioningFns.canView(school, viewingUser);
  }

  // returns cohorts freshman-to-senior
  getCurrentCohorts () {
    const currentCohorts = Array(4);
    const currentSeniorCohort = CurrentSchoolYear.ENDFULL;
    currentCohorts.fill(currentSeniorCohort);
    return _.map(currentCohorts, (cohort, i) => _.toString(+cohort + i));
  }

  /* istanbul ignore next */
  getStudentYearForCohort (cohort: string) {
    const cohortVal: number = Number(cohort);
    const currentSeniorCohortVal: number = CurrentSchoolYear.ENDFULL_VAL;
    if (cohortVal < currentSeniorCohortVal) return currentSeniorCohortVal - cohortVal + 4;
    if (cohortVal === currentSeniorCohortVal) return 4;
    if (cohortVal > currentSeniorCohortVal) return 4 - (cohortVal - currentSeniorCohortVal);
    return null;
  }

  getSeniorAndSuperCohorts (school: ISchool) {
    const { uniqueCohorts } = school;
    const currentSeniorCohort = CurrentSchoolYear.ENDFULL;
    const seniorAndSuperCohorts = _.reduce(
      uniqueCohorts,
      (a, cohort) => {
        if (cohort <= currentSeniorCohort) a.push(cohort);
        return a;
      },
      [],
    );
    return seniorAndSuperCohorts;
  }

  // Unlike getSeniorAndSuperCohorts() this function uses Cohort constant instead
  // instead of CurrentSchoolYear constant to determine what the senior cohort is
  // The Cohort constant has a studentYear prop on it that gets rotated to
  // make the rising seniors act like seniors in the middle of the summer for
  // the purposes of early grad planning
  getSeniorAndSuperCohortsForGradPlanning (school: ISchool) {
    const { uniqueCohorts } = school;
    const currentSeniorCohort = _.find(Cohort, { studentYear: 4 });
    const seniorAndSuperCohorts = _.reduce(
      uniqueCohorts,
      (a, cohort) => {
        if (Number(cohort) <= currentSeniorCohort.value) a.push(cohort);
        return a;
      },
      [],
    );
    return seniorAndSuperCohorts;
  }

  getRegentsMetricForEffectiveCohortAndStudent (school, effectiveCohort, effectiveStudentYear) {
    let metrics;
    let metricForStudentYear;
    const {
      onTrackMetrics: { regentsExpectedByAdmin },
    } = school;
    const { allCohorts: allCohortMetrics } = regentsExpectedByAdmin;

    if (!allCohortMetrics || !allCohortMetrics.length) {
      metrics = regentsExpectedByAdmin[`cohort_${effectiveCohort}`];
      metricForStudentYear = _.find(metrics, { studentYear: effectiveStudentYear });
    } else {
      metrics = allCohortMetrics;
      metricForStudentYear = _.find(metrics, { studentYear: effectiveStudentYear });
    }
    return metricForStudentYear;
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getCombinedMasterProgramCodeDeck (school: ISchool, termNumber?: number): ICoursePartial[] {
    const masterSchedule = this.getMasterScheduleForTermYear(school);
    const codeDeck: any = this.getSchoolCodeDeck(school, termNumber);
    const flattenedCodeDeck = _.reduce(
      codeDeck,
      (a, subj) => {
        a = a.concat(subj);
        return a;
      },
      [],
    );

    const _allCoursesCache = [...masterSchedule, ...flattenedCodeDeck];

    _.sortBy(_allCoursesCache, 'courseCode');

    return _allCoursesCache;
  }

  isHighSchool ({ schoolType }: ISchool): boolean {
    return _.includes(HIGH_SCHOOL_TYPES, schoolType);
  }

  isMiddleSchool ({ schoolType }: ISchool): boolean {
    return _.includes(MIDDLE_SCHOOL_TYPES, schoolType);
  }

  isElementarySchool ({ schoolType }: ISchool): boolean {
    return _.includes(ELEMENTARY_SCHOOL_TYPES, schoolType);
  }

  isEmsSchool ({ schoolType }: ISchool): boolean {
    return _.includes(EMS_SCHOOL_TYPES, schoolType);
  }

  isHybridSchool ({ schoolType }: ISchool): boolean {
    return (schoolType === '6 to 12') || (schoolType === 'K to 12');
  }

  getSchoolLevel (schoolType: TValidSchoolTypes): string[] {
    const schoolLevelOptions = {
      'K to 5': ['ES'],
      'K to 8': ['ES', 'MS'],
      'K to 12': ['ES', 'MS', 'HS'],
      '6 to 8': ['MS'],
      '6 to 12': ['MS', 'HS'],
      'Small HS': ['HS'],
      'Large HS': ['HS'],
      Transfer: ['HS'],
      All: ['ES', 'MS', 'HS'], // used by shelter network
    };
    return schoolLevelOptions[schoolType];
  }

  // Returns school types as used in side nav configs
  // Note: distinction between Transfer and Non-Transfer high school
  getSchoolLevelTypes (schoolType: TValidSchoolTypes): string[] {
    return schoolType === 'Transfer' ? ['TRANSFER'] : this.getSchoolLevel(schoolType);
  }

  // PBAT SCHOOL SPECIFIC
  isPbatSchool ({ pbat }: ISchool) {
    return pbat;
  }

  @memoized({ exceptWhen: SCHOOL_IS_MUTABLE })
  getOrderedTermInfoArray (school: ISchool) {
    const termInfo = school.termInfo;
    return _.orderBy(termInfo, 'yearTerm', 'desc');
  }

  isSummerSchool (school: ISummerSchool, viewingUser: IUser): boolean {
    const { _hasSummerSchoolStudents } = school;
    const { summerSiteSchoolId } = viewingUser;
    const isSummerSchool = _hasSummerSchoolStudents || !!summerSiteSchoolId;
    return isSummerSchool;
  }

  public getGridType (school: ISchool): TGrid {
    let gridType: TGrid;
    if (this.isAimSchool(school._id)) {
      gridType = 'aim';
    } else if (this.isCharterSchool(school)) {
      gridType = 'charter';
    } else if (this.is9To12School(school)) {
      gridType = 'hs';
    } else if (this.isEsSchool(school)) {
      gridType = 'es';
    } else if (this.is6To12School(school)) {
      gridType = '6To12';
    } else if (this.isMsSchool(school)) {
      gridType = 'ms';
    } else if (this.isTransferSchool(school)) {
      gridType = 'transfer';
    } else if (this.isKTo8School(school)) {
      gridType = 'kTo8';
    } else if (this.isKTo12School(school)) {
      gridType = 'kTo12';
    }
    const isSummer = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
    if (isSummer) gridType = `summer_${gridType}` as TGrid;
    return gridType;
  }

  public isAimSchool (dbn: string): boolean {
    return _.includes(AIM_SCHOOLS_DBNS, dbn);
  }

  public is9To12School ({ schoolType }: ISchool): boolean {
    return _.includes(HIGH_SCHOOL_9_TO_12_TYPES, schoolType);
  }

  public is6To12School ({ schoolType }: ISchool): boolean {
    return schoolType === '6 to 12';
  }

  public isMsSchool ({ schoolType }: ISchool): boolean {
    return schoolType === '6 to 8';
  }

  public isEsSchool ({ schoolType }: ISchool): boolean {
    return schoolType === 'K to 5';
  }

  public isKTo8School ({ schoolType }: ISchool): boolean {
    return schoolType === 'K to 8';
  }

  public isKTo12School ({ schoolType }: ISchool): boolean {
    return schoolType === 'K to 12';
  }

  public hasCorrectSchoolType (school: ISchool, schoolTypes: (TValidSchoolTypeCats | TGrid)[] = [], contextPartnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    if (contextPartnerType !== PartnerTypes.SCHOOL) return true;
    if (!schoolTypes.length || _.isEqual(schoolTypes, ['all'])) return true;
    const schoolTypeValidators = {
      es: this.isElementarySchool,
      ms: this.isMiddleSchool,
      hs: this.isHighSchool,
      transfer: this.isTransferSchool,
      ems: this.isEmsSchool,
    };
    return schoolTypes.some(st => schoolTypeValidators[st](school));
  }
}
