import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { cloneDeep, identity } from 'lodash';
import { combineLatest, Observable, Subject, Unsubscribable } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { LoadUsers, getSchool, getUsersEntities, getUsersLoadedStatus } from '../../../../store';
import { IStudentEditableField } from '../../../constants/paths/student-editable-map.constant';
import { SorterColumnDataType } from '../../../constants/sorter-column-data-type.constant';
import { BatchEditService, TBatchEditSaveValue } from '../../../services/batch-edit-service/batch-edit-service';
import { IUser, IUserMiniWithRole } from '../../../typings/interfaces/user.interface';
import { IEditableRecord } from './../../../services/batch-edit-service/batch-edit-service';
import { PickerService } from 'Src/ng2/shared/services/picker/picker.service';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

interface IBatchEditDropdownItems {
  areUsers: boolean,
  items: Array<IDropdownOption>,
}

@unsubscribeComponent
@Component({
  selector: 'batch-edit-modal-input',
  templateUrl: './batch-edit-modal-input.component.html',
  styleUrls: ['./batch-edit-modal-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BatchEditModalInputComponent implements OnInit, OnDestroy {
  // conditions
  public isInput: boolean;
  public isCheckbox: boolean;
  public isDropdown: boolean;
  public hasSearchableAcOptions: boolean;
  public isArray: boolean;
  public isRadio: boolean;
  public inputGroup: FormGroup;
  public dropdownItems: IBatchEditDropdownItems;
  public radioItems: string[];
  public acOptions;
  public schoolUsers;
  public schoolUsersFilteredForPointPeople;
  public schoolUsersFilteredForAdvisors : IUserMiniWithRole[];

  public usersEntities$: Observable<IUserMiniWithRole[]>;
  public destroy$: Subject<boolean> = new Subject<boolean>();

  private searchValSub: Unsubscribable;

  @Input() field: IStudentEditableField;
  @Output() newValueChange = new EventEmitter<TBatchEditSaveValue>();

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private batchEditService: BatchEditService,
    private pickerService: PickerService,
    @Inject(MAT_DIALOG_DATA) public data: any[],
  ) {
    this.dropdownItems = {
      areUsers: null,
      items: null,
    };
  }

  ngOnInit (): void {
    const field = cloneDeep(this.field);
    const { dataType } = field;
    this._clearCurrentFormFieldType();
    this._setFormFieldType(field);
    this.inputGroup = this._buildForm();
    this._setSchoolUsers().pipe(
      tap(() => {
        if (dataType !== SorterColumnDataType.STRING && dataType !== SorterColumnDataType.RADIO) {
          this._setDropdown(field);
        }
      }),
    ).subscribe();


    this.hasSearchableAcOptions = dataType === SorterColumnDataType.STRING && !!field.dataTypeOptions?.values;
    if (this.hasSearchableAcOptions) {
      this.initSearchableAcOptions();
    }

    // Set default selected value to null if field can be null
    const canBeNull = field?.dataTypeOptions?.canBeNull;
    if (canBeNull) {
      this.newValueChange.emit(null);
    }

    this.inputGroup.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(value => {
        this.onTextInput(value.textarea);
      }),
    ).subscribe();
  }

  ngOnChanges (changes): void {
    const { field } = changes;
    if (field && !field.firstChange) {
      this._clearCurrentFormFieldType();
      this.resetInputType();
      this._setFormFieldType(field.currentValue);
      if (
        field.currentValue.dataType !== SorterColumnDataType.STRING &&
        field.currentValue.dataType !== SorterColumnDataType.RADIO
      ) {
        this._setDropdown(changes.field.currentValue);
      }
    }
  }

  ngOnDestroy (): void {
    this._clearCurrentFormFieldType();
  }

  private initSearchableAcOptions (): void {
    this.acOptions = this.field.dataTypeOptions.values;

    const searchVal$ = this.inputGroup.controls.textarea.valueChanges.pipe(startWith(''));
    this.searchValSub = searchVal$.pipe(
      map(searchVal => this.pickerService.filterAcOptions(this.field.dataTypeOptions.values, searchVal)),
      tap(options => {
        this.acOptions = options;
      }),
    ).subscribe();
  }

  resetInputType () {
    this.isInput = false;
    this.isDropdown = false;
    this.isRadio = false;
    this.radioItems = null;
    this.isArray = false;
  }

  _setSchoolUsers (): Observable<IUser[]> {
    return combineLatest([
      this.store.select(getUsersLoadedStatus),
      this.store.pipe(select(getSchool)),
    ]).pipe(
      tap(([loaded, { _id: schoolId }]) => {
        if (!loaded) this.store.dispatch(new LoadUsers({ schoolId }));
      }),
      filter(([loaded]) => identity(loaded)),
      switchMap(([loaded, { _id: schoolId }]) => {
        return this.store.select(getUsersEntities).pipe(
          tap(users => {
            this.batchEditService.sortUsersByLastName(users as IUserMiniWithRole[]);
            this.schoolUsers = users;
            this.schoolUsersFilteredForPointPeople = this.batchEditService.getAuthorizedPointPeople(users as IUserMiniWithRole[], schoolId);
            this.schoolUsersFilteredForAdvisors = this.batchEditService.getAuthorizedAdvisors(users as IUserMiniWithRole[], schoolId);
          }),
        );
      }),
      take(1),
    );
  }

  _clearCurrentFormFieldType (): void {
    this.isInput = this.isCheckbox = this.isDropdown = this.hasSearchableAcOptions = this.isArray = false;
  }

  _setFormFieldType (currentValue: IEditableRecord): void {
    switch (currentValue.dataType) {
      case SorterColumnDataType.STRING:
        this.isInput = true;
        break;
      case SorterColumnDataType.BOOLEAN_YES_NO:
      case SorterColumnDataType.ENUM:
      case SorterColumnDataType.REGENTS_ADMIN:
      case SorterColumnDataType.USER_MINI:
        this.isDropdown = true;
        break;
      case SorterColumnDataType.ARRAY:
        this.isArray = true;
        break;
      case SorterColumnDataType.RADIO:
        this.isRadio = true;
        this.radioItems = currentValue.dataTypeOptions.values;
        break;
      default:
        throw new Error('Invalid datatype value');
    }
  }

  getUsers (field: IStudentEditableField): IUserMiniWithRole[] {
    let users: IUserMiniWithRole[];
    // advisor and guidance counselors have a path 'pointPeople', but can be assigned to any school user (JE)
    if (field.type === 'ADVISOR' || field.type === 'GUIDANCE_COUNSELOR') {
      users = this.schoolUsersFilteredForAdvisors;
    } else if (field.path === 'pointPeople') {
      users = this.schoolUsersFilteredForPointPeople;
    } else {
      users = this.schoolUsers;
    }
    return users;
  }

  _setDropdown (field: IStudentEditableField): void {
    const users = this.getUsers(field);
    const areUsers: boolean = !!users;
    let items: IDropdownOption[] = null;

    switch (field.dataType) {
      case SorterColumnDataType.USER_MINI:
        items = this.batchEditService.getUsersDropdown(users);
        break;
      case SorterColumnDataType.BOOLEAN_YES_NO:
        items = this.batchEditService.getBooleanDropdown(field);
        break;
      case SorterColumnDataType.ENUM:
      case SorterColumnDataType.REGENTS_ADMIN:
        items = this.batchEditService.getEnumDropdown(field);
        break;
      default:
        throw new Error(`Unhandled data type in batch edit field: ${field.dataType}`);
    }
    this.dropdownItems = { areUsers, items };
  }

  _buildForm (): FormGroup {
    const form = this.formBuilder.group({
      textarea: [null, Validators.required],
      dropdown: [null, Validators.required],
    });

    return form;
  }

  onTextInput (textValue: string): void {
    this.newValueChange.emit(textValue);
  }

  onSelection (item: IDropdownOption): void {
    const newValue = this.batchEditService.getFieldValueFromOption(item, this.field.dataType, this.getUsers(this.field));
    this.newValueChange.emit(newValue);
  }

  onAcSelection ($event) {
    this.inputGroup.controls.textarea.setValue($event);
  }

  clearAcSelection () {
    this.acOptions = this.field.dataTypeOptions.values;
    this.inputGroup.controls.textarea.reset('');
  }

  onRadioChange (item: string): void {
    this.newValueChange.emit(item);
  }
}
