import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvProgressBarTileComponent } from './nv-progress-bar-tile.component';
import { NvProgressBarModule } from '../../nv-progress-bar/nv-progress-bar.module';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';

@NgModule({
  imports: [CommonModule, NvProgressBarModule, NvSharedTooltipModule],
  declarations: [NvProgressBarTileComponent],
  exports: [NvProgressBarTileComponent],
})
export class NvProgressBarTileModule {}
