import { ColDef } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';

@Component({
  selector: 'dash-cell-renderer',
  styleUrls: ['./dash-cell-renderer.component.scss'],
  templateUrl: './dash-cell-renderer.component.html',
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashCellRenderer {
  public displayedValue: string;

  agInit (params: any) {
    const { value, colDef } = params;
    if (colDef.cellRendererParams?.params) {
      this.displayedValue = this.paramsValueFormatter(value, colDef);
    } else {
      this.displayedValue = this.valueFormatter(value);
    }
  };

  // columns can optionally leverage cellRendererParams to dictate the cell format
  private paramsValueFormatter (value: string, columnDef: ColDef) {
    let displayedValue: string;

    const cellRendererParams = JSON.parse(columnDef.cellRendererParams.params);

    switch (true) {
      case value === null:
        displayedValue = EM_DASH;
        break;
      case cellRendererParams.cellValueType === 'text':
        displayedValue = value;
        break;
      case cellRendererParams.cellValueType === 'percent':
        displayedValue = this.changeToPercent(value);
        break;
      default:
        displayedValue = value;
        break;
    }
    return displayedValue;
  }

  public valueFormatter (value: string) {
    let displayedValue: string;

    switch (true) {
      case value === null:
        displayedValue = EM_DASH;
        break;
      case !isNaN(parseFloat(value)):
        displayedValue = this.changeToPercent(value);
        break;
      default:
        displayedValue = value;
        break;
    }

    return displayedValue;
  }

  public changeToPercent (value: string) {
    const percentage = parseFloat(value);
    const roundedPercentage = Math.round(percentage * 100);
    return roundedPercentage.toString() + '%';
  }
}
