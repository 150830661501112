import { Injectable } from '@angular/core';
import { EventFormatterService } from '../event-formatter.service';
import { MixpanelService } from '../mixpanel.service';
import { PORTAL_TYPES, TPortalLocation } from '../../../typings/interfaces/portal.interface';
import { EVENT_TYPES } from '../../../typings/interfaces/mixpanel.interface';
import { TMiscOrigins, TMiscPageActions } from '../event-interfaces/misc-page';
import { TAllSupportActions, TSupportsPages } from '../event-interfaces/supports-action';

@Injectable()
export class SharedEventTrackers {
  constructor (
        private eventFormatterService: EventFormatterService,
        private mixpanelService: MixpanelService,
  ) {}

  public trackLefthandNavEvent ({ navItem, portal }:{navItem: string, portal: TPortalLocation}): void {
    const event = this.eventFormatterService.getLefthandNavEvent({
      navItem,
      portal,
    });
    this.mixpanelService.trackEvents([event]);
  }

  public trackMiscPageView ({ pageName, origin, portal }: { pageName: string, origin: TMiscOrigins, portal: keyof typeof PORTAL_TYPES}): void {
    const metadata = {
      portal,
      action: EVENT_TYPES.VIEWED,
      origin,
      pageName,
    };
    const event = this.eventFormatterService.getMiscPageViewEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }

  public trackMiscPageAction ({ action, pageName, origin, portal }: { action: TMiscPageActions, pageName: string, origin: TMiscOrigins, portal: keyof typeof PORTAL_TYPES}) {
    const metadata = {
      portal,
      action,
      origin,
      pageName,
    };
    const event = this.eventFormatterService.getMiscPageActionEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }

  public trackSupportViewEvent ({ pageName, action }: { pageName: TSupportsPages, action?: TAllSupportActions }): void {
    const metadata = {
      view: pageName,
      action: action || EVENT_TYPES.VIEWED,
      portal: PORTAL_TYPES.SCHOOL as TPortalLocation,
    };
    const event = this.eventFormatterService.getSupportAttendanceEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }

  public trackSupportAttendanceEvent ({ pageName, action } : { pageName: TSupportsPages, action: TAllSupportActions }): void {
    const metadata = {
      view: pageName,
      action,
      portal: PORTAL_TYPES.SCHOOL as TPortalLocation,
    };
    const event = this.eventFormatterService.getSupportAttendanceEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }

  public trackSupportOrStudentSupportEvent ({ pageName, action } : { pageName: TSupportsPages, action: TAllSupportActions }): void {
    const metadata = {
      view: pageName,
      action,
      portal: PORTAL_TYPES.SCHOOL as TPortalLocation,
    };
    const event = this.eventFormatterService.getStudentSupportOrSupportEvent(metadata, action);
    this.mixpanelService.trackEvents([event]);
  }

  public trackClickedSupportButton ({ element, view, portal }: { element: 'Create new support button', view: 'SUPPORT-LIST', portal: keyof typeof PORTAL_TYPES }) {
    const metadata = {
      action: EVENT_TYPES.CLICKED,
      element,
      view,
      portal,
    };
    const event = this.eventFormatterService.getClickedSupportButtonEvent(metadata);
    this.mixpanelService.trackEvents([event]);
  }
}
