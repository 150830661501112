<nv-sidebar-list-header
  *ngIf="customTitle"
  [title]="customTitle"
  [subtitle]="subtitle"
  [shouldAnimateSubtitle]="shouldAnimateSubtitle"
  [icon]="titleIcon"
  [iconTooltip]="titleIconTooltip"
>
  <!-- This content will be projected into the nv-sidebar-list-header component's ng-content slot -->
  <nv-button
    title-right
    *ngIf="!!titleButtonLabel"
    [icon]="'add-small-blue'"
    type="secondary"
    size="small"
    (clickButton)="handleTitleButtonClick()"
    >{{ titleButtonLabel }}
  </nv-button>
</nv-sidebar-list-header>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [ngClass]="toolsMode ? 'tools-mode' : ''">
  <!-- Leaf Nodes -->
  <mat-tree-node
    class="leaf-node"
    [ngClass]="{ 
      'root-node': node.level === 0, 
      'child-node': node.level > 0,
      'last-accordion-child': node.lastChild === true,
      'selected-highlight': isNodeHighlighted(node),
    }"
    *matTreeNodeDef="let node"
  >
    <ng-container *ngIf="node.expandAs === 'dropdown' && node.children; else elseTemplate">
      <!-- Dropdown Node -->
      <nv-sidebar-item
        [id]="node.key + '-sidebar-item'"
        [label]="node.human"
        [isDisabled]="node.disabled"
        [leftDefaultIcon]="node.leftDefaultIcon"
        [leftSelectedIcon]="node.leftSelectedIcon"
        [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
        [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
        [rightDefaultIcon]="'dropdown-blue'"
        [ngClass]="{ 'last-child': nodeIsLastNode(node), 'disabled' : node.disabled }"
        [matMenuTriggerFor]="dropdownMenu"
      ></nv-sidebar-item>
      <mat-menu #dropdownMenu="matMenu" [overlapTrigger]="true" yPosition="below" panelClass="nv-mat-menu">
        <div *ngFor="let suboption of node.children">
          <nv-dropdown-item (selectItem)="handleSelect($event)" [option]="suboption"></nv-dropdown-item>
        </div>
      </mat-menu>
    </ng-container>

    <ng-template #elseTemplate>
      <!-- Basic Leaf Node -->
      <!--TODO: REMOVE isExternalUrl WHEN CAREER_DIRECTORY IS NO LONGER NEEDED -->
      <nv-sidebar-item
        nvTooltip
        [id]="node.key + '-sidebar-item'"
        class="basic-leaf-node"
        [label]="node.human"
        [url]="node.url"
        [queryParams]="node.queryParams"
        [isSelected]="nodeIsSelected(node)"
        [isDisabled]="node.disabled"
        [isChild]="node.level > 0"
        [leftDefaultIcon]="node.leftDefaultIcon"
        [leftSelectedIcon]="node.leftSelectedIcon"
        [rightDefaultIcon]="node.rightDefaultIcon"
        [rightHoverIcon]="node.rightHoverIcon"
        [rightHoverIconOptions]="node.rightHoverIconOptions"
        [rightSelectedIcon]="node.rightSelectedIcon"
        [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
        [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
        [hasBetaFlag]="node.hasBetaFlag"
        [isExternalUrl]="node.isExternalUrl"
        [tooltipData]="node.tooltipData"
        [ngClass]="{ 'last-child': nodeIsLastNode(node) }"
        (sidebarItemSelect)="handleSelect(node.key)"
        (iconMenuSelect)="onIconMenuSelect($event, node.key)"
      ></nv-sidebar-item>
    </ng-template>
  </mat-tree-node>

  <!-- Expandable Accordion Nodes -->
  <mat-tree-node
    class="root-node accordion-node"
    *matTreeNodeDef="let node; when: hasChild"
    [ngClass]="{'selected-highlight': isNodeHighlighted(node)}"
  >
    <nv-sidebar-item
      matTreeNodeToggle
      [id]="node.key + '-sidebar-item'"
      [leftDefaultIcon]="node.leftDefaultIcon"
      [leftSelectedIcon]="node.leftSelectedIcon"
      [leftDefaultIconAriaLabel]="node.leftDefaultIconAriaLabel"
      [leftSelectedIconAriaLabel]="node.leftSelectedIconAriaLabel"
      [rightDefaultIcon]="treeControl.isExpanded(node) ? caretDownDefaultIcon : caretRightDefaultIcon"
      [rightSelectedIcon]="treeControl.isExpanded(node) ? caretDownSelectedIcon : caretRightSelectedIcon"
      [label]="node.human"
      [isSelected]="nodeIsSelected(node)"
      [isDisabled]="node.disabled"
      [ngClass]="{ 'last-child': nodeIsLastNode(node) }"
      [isChild]="node.level > 0"
      (sidebarItemSelect)="handleSelect(node.key)"
    ></nv-sidebar-item>
  </mat-tree-node>
</mat-tree>
