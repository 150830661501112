<div class="nv-sidebar-item-container" [ngClass]="{ selected: isSelected, disabled: isDisabled }">
  <a class="unformatted-link mat-ripple"
     *ngIf="!!url"
     [routerLink]="url"
     [queryParams]="queryParams"
     >
     <button
       matRipple
       class="nv-sidebar-item"
       [ngClass]="{ selected: isSelected, disabled: isDisabled, child: isChild, 'left-icon-layout': !!leftDefaultIcon || !!leftSelectedIcon }"
       (click)="handleClick($event)"
       >
       <nv-icon *ngIf="!!leftDefaultIcon && !isSelected" class="sidebar-item-icon" [name]="leftDefaultIcon"></nv-icon>
       <nv-icon *ngIf="!!leftSelectedIcon && isSelected" class="sidebar-item-icon" [name]="leftSelectedIcon"></nv-icon>
       <span class="sidebar-item-label">{{ label }}</span>
       <nv-icon *ngIf="!!rightDefaultIcon && !isSelected" class="sidebar-item-dropdown" [name]="rightDefaultIcon"></nv-icon>
       <nv-icon *ngIf="!!rightSelectedIcon && isSelected" class="sidebar-item-dropdown" [name]="rightSelectedIcon"></nv-icon>
       <span *ngIf="hasBetaFlag" class="sidebar-item-beta-flag">{{ getBetaMessage() }}</span>
     </button>
  </a>
  <button
    *ngIf="!url"
    matRipple
    class="nv-sidebar-item"
    [ngClass]="{ selected: isSelected, disabled: isDisabled, child: isChild }"
    (click)="handleClick($event)"
    >
    <nv-icon
      *ngIf="!!leftDefaultIcon && !isSelected"
      class="sidebar-item-icon"
      [name]="leftDefaultIcon"
      nvAriaLabel
      [ariaLabelText]="leftDefaultIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <nv-icon
      *ngIf="!!leftSelectedIcon && isSelected"
      class="sidebar-item-icon"
      [name]="leftSelectedIcon"
      nvAriaLabel
      [ariaLabelText]="leftSelectedIconAriaLabel"
      ariaTargetElement="svg"
    ></nv-icon>
    <span class="sidebar-item-label">{{ label }}</span>
<!--  TODO: REMOVE WHEN CAREER_DIRECTORY IS NO LONGER NEEDED -->
    <ng-container *ngIf="isExternalUrl">
      <nv-icon
        *ngIf="!!rightDefaultIcon && !isSelected"
        class="sidebar-item-url"
        [name]="rightDefaultIcon"
        nvAriaLabel
        [ariaLabelText]="rightDefaultIconAriaLabel"
        ariaTargetElement="svg"
      ></nv-icon>
      <nv-icon
        *ngIf="!!rightSelectedIcon && isSelected"
        class="sidebar-item-url"
        [name]="rightSelectedIcon"
        nvAriaLabel
        [ariaLabelText]="rightSelectedIconAriaLabel"
        ariaTargetElement="svg"
      ></nv-icon>
    </ng-container>
    <ng-container *ngIf="!isExternalUrl">
      <nv-icon
        *ngIf="!!rightDefaultIcon && !isSelected"
        class="sidebar-item-dropdown"
        [name]="rightDefaultIcon"
        nvAriaLabel
        [ariaLabelText]="rightDefaultIconAriaLabel"
        ariaTargetElement="svg"
      ></nv-icon>
      <nv-icon
        *ngIf="!!rightSelectedIcon && isSelected"
        class="sidebar-item-dropdown"
        [name]="rightSelectedIcon"
        nvAriaLabel
        [ariaLabelText]="rightSelectedIconAriaLabel"
        ariaTargetElement="svg"
      ></nv-icon>
    </ng-container>
    <span *ngIf="hasBetaFlag" class="sidebar-item-beta-flag">{{ getBetaMessage() }}</span>
  </button>
  <nv-icon
    *ngIf="!!rightHoverIcon"
    class="sidebar-item-dropdown-menu"
    [name]="rightHoverIcon"
    [matMenuTriggerFor]="isDisabled ? null : dropdownMenu.menu"
  ></nv-icon>
  <nv-dropdown-menu
    #dropdownMenu="nvDropdownMenu"
    [options]="rightHoverIconOptions"
    (selectItem)="handleSelectSubOption($event)"
    ></nv-dropdown-menu>
</div>
