/* eslint-disable no-unused-vars */

export enum EVENT_TYPES {
  VIEWED = 'Viewed',
  CREATED = 'Created',
  UPDATED = 'Updated',
  DELETED = 'Deleted',
  ASSIGNED = 'Assigned',
  CLICKED = 'Clicked',
}

export enum EVENT_NAMES {
  SERVERSIDE_GRIDVIEW = 'Server-side GridView',
  SERVER_SIDE_ENTITY = 'Server-side Entity',
  HOMEPAGE_EVENT = 'Home Page Action',
  CLICKED_TILE = 'Clicked Tile',
  LEFTHAND_NAV = 'Clicked LH Nav Item',
  PROFILE_NAV = 'Clicked Profile Nav',
  GRID_COLUMNS_ACTION = 'Grid Columns Action',
  GRID_SHARE_URL = 'Clicked Grid Share URL Option',
  VIEWED_CONTENT_AREA_DASHBOARD = 'Viewed Content Area Dashboard',
  VIEWED_CONTENT_AREA_LIST = 'Viewed Content Area List',
  GENERATED_REPORT = 'Generated Report',
  STUDENT_PROFILE_ACTION = 'Student Profile Action',
  SUPPORTS_ACTION = 'Supports Action',
  EXPERIENCE_ACTION = 'Experience Action',
  STUDENT_PATH_ACTION = 'Student Path Action',
  COMPLETED_BATCH_ACTION = 'Completed Batch Action',
  VIEWED_SUPPLEMENTAL_ADVISING = 'Viewed Supplemental Advising',
  EXPORTED_CSV = 'Exported CSV',
  NOTE_ACTION = 'Note Action',
  FAMILY_PROFILE_ACTION = 'Family Profile Action',
  FAMILY_LIST_ACTION = 'Family List Action',
  MY_GRID_VIEWS = 'Clicked My Grid Views Dropdown Option',
  CAD_TO_GRID = 'Clicked Content Area Dashboard Table',
  TASK_ACTION = 'Task Action',
  VIEWED_OTHER_REGENTS = 'Viewed Other Regents Page',
  REGENTS_RESULTS_ACTION = 'Regents Results Action',
  MOCK_REGENTS_ACTION = 'Mock Regents Action',
  VIEWED_PASS_RATES = 'Viewed Pass Rates Page',
  PASS_RATES_ACTION = 'Pass Rates Action',
  COLLEGE_NOW_ACTION = 'College Now Action',
  MISC_PAGE_ACTION = 'Misc Page Action', // for pages in Other tools, Settings, Data Uploads, and other small nav items that are not otherwise tracked
}

export type TEventName = typeof EVENT_NAMES[keyof typeof EVENT_NAMES];
export type TEventType = typeof EVENT_TYPES[keyof typeof EVENT_TYPES];
