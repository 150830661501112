import { EVENT_NAMES, TEventName, TEventType } from 'Src/ng2/shared/typings/interfaces/mixpanel.interface';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { PORTAL_TYPES } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { EntityId } from '../../../../school/server-side-grid/server-side-grid.types';
import { TMixpanelEvent } from '../mixpanel.service';

// type TPortal = 'School' | 'Network';

export interface IServerSideGridViewEventMetadata {
  eventName: TEventName;
  eventType: TEventType;
}

export interface IGridViewMetadata extends IServerSideGridViewEventMetadata {
  gridView: {
    gridViewId: string;
    gridViewName: string;
    gridViewType: string;
    columns: {
      added: any[];
      deleted: any[];
    };
  };
  auditInfo: {
    userRole: string;
    gridOrSchoolType: string;
  };
  portal: PORTAL_TYPES;
}

export interface IGridShareUrlMetadata {
  eventType: TEventType;
  contextPartnerType: TValidPartnerTypes;
  url: string;
}

export interface IGridEntityMetaData extends IServerSideGridViewEventMetadata {
  entity: EntityId;
  gridType: string;
}

export const getServerSideGridViewEvent = <TMetadata extends IGridViewMetadata>(
  metaData: TMetadata,
): TMixpanelEvent<TMetadata> => ({
    event: metaData.eventName,
    metaData,
  });

export const getGridShareUrlEvent = (data: IGridShareUrlMetadata): TMixpanelEvent<IGridShareUrlMetadata> => {
  const { eventType, contextPartnerType, url } = data;
  return {
    event: EVENT_NAMES.GRID_SHARE_URL,
    metaData: {
      eventType,
      contextPartnerType,
      url,
    },
  };
};
