import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { toKebabFromPascal } from 'projects/shared/nvps-libraries/design/utilities/helpers';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { StudentTasksNotesService } from '../../tasks-notes-activity-table/tasks-notes-activity-table.service';
import { TSupportsModalView } from 'Src/ng2/shared/modals/support/support-modal.interface';

@Component({
  selector: 'app-menu-cell',
  templateUrl: './menu-cell-renderer.component.html',
  styleUrls: ['./menu-cell-renderer.component.scss'],
})

/*
 *  Params:
 *    iconname: specifies which icon to show (default is the more icon)
 *    disabled: enables or disables the onClick action
 *    tooltip: shows a tooltip if you hover over the menu icon
 *    options: specifies the menu options
 *    parent: passes a string to the menu cell renderer of the parent component
 *       - dictates if the cell renderer needs to do anything more (eg. regents supports table, it needs to open a modal)
 */
export class MenuCellRenderer implements ICellRendererAngularComp, OnInit {
  @Input() input: {
    options: any;
    disabled: boolean;
    tooltip: any;
    onSelect: void;
  };

  public icon: String;
  public disabled: Boolean = false;
  public tooltip;
  public options;
  public data;
  public cellParams: any;
  protected field: string | undefined;
  public params: ICellRendererParams;

  constructor (
    public ApiService: ApiService,
    public ModalsService: ModalsService,
    private studentTasksNotesService: StudentTasksNotesService,
  ) {}

  // agInit is used by the grid cell renderer pattern
  public agInit (params: ICellRendererParams): void {
    this.params = params;
    this.field = params?.colDef?.field;

    if (params.data) {
      this.data = params.data;
    }

    const cellParams = this.params?.colDef?.cellRendererParams?.params;
    this.cellParams = cellParams;
    if (cellParams.iconname) {
      const iconName = cellParams.iconname.trim();
      this.setIcon(iconName);
    } else {
      this.setIcon('more-large-blue');
    }
    if (cellParams.disabled) {
      this.disabled = cellParams.disabled;
    }
    // Student Panel, Tasks/Notes/Activity modal need to check permissions
    if (cellParams.checkTaskEditPermissions) this.disabled = !this.studentTasksNotesService.getEditTasksPermissions(cellParams.parent.currentUser, this.cellParams.parent.partnerType, this.data);
    // my-tasks component only needs to check if a task is ACTIVE
    if (cellParams.checkActiveTask) {
      // Edits are only allowed for todo tasks
      const isActiveTask = this.data.status === 'ACTIVE';
      this.disabled = !isActiveTask;
    }
    if ((cellParams.checkTaskEditPermissions && this.disabled) || (cellParams.checkActiveTask && this.disabled)) {
      this.tooltip = 'Edit and delete access not available';
    }
    if (cellParams.tooltip) {
      this.tooltip = cellParams.tooltip;
    }
    if (cellParams.options) {
      this.options = cellParams.options;
    }
    if (cellParams.onSelect) {
      this.onSelect = cellParams.onSelect;
    }
  }

  public onSelect (event: string, data: any) {
    if (this.cellParams.parentName === 'my-tasks') {
      this.cellParams.parent.onMenuCellRendererClick(event, data);
    }
    if (this.cellParams.parent) {
      const parent = this.cellParams.parent;
      switch (parent) {
        case 'Regents Supports Table': {
          this.onSelect = this.supportOnSelect;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  // ngOnInit is used by the list dynamic component pattern
  public ngOnInit (): void {}

  protected setIcon (iconName: string): void {
    this.icon = toKebabFromPascal(iconName);
  }

  public showTooltip (): void {}

  public supportOnSelect (e, data) {
    switch (e) {
      case 'Duplicate': {
        this.patchSupport('DUPLICATE', this.data._id);
        break;
      }
      case 'Edit': {
        this.patchSupport('EDIT', this.data._id);
        break;
      }
      case 'Delete': {
        this.deleteSupport();
        break;
      }
    }
  }

  patchSupport (mode, id): void {
    this.ApiService.getSupport(id).subscribe(support => {
      const data = {
        mode,
        support,
        schoolId: support.schoolId,
        view: 'STUDENT-PROFILE' as TSupportsModalView,
      };
      this.ModalsService.openSupportModal(data);
    });
  }

  deleteSupport (): void {
    const modalOptions = {
      mode: 'DELETE',
      supportId: this.data._id,
      supportName: this.data.Tooltip ?? this.data.STUB,
    };
    this.ModalsService.openSupportStatusModal(modalOptions);
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }
}
