import { TSupportModalMode, TSupportsModalView } from './../../../../../shared/modals/support/support-modal.interface';
import { Unsubscribable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IColumn, IRowData } from 'Src/ng2/shared/models/list-models';
import { ISupportStatusModalComponentData } from './../../../../../shared/modals/support/support-status-modal.component.ts/support-status-modal.component';
import { ApiService } from './../../../../../shared/services/api-service/api-service';
import { IDropdownOption } from '../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

interface IKebabOptions {
  human: string;
  action: TSupportModalMode;
}

export const SUPPORT_OPTIONS_MENU_KEBAB_OPTIONS: { [key: string]: IKebabOptions } = {
  DUPLICATE: {
    human: 'Duplicate support',
    action: 'DUPLICATE',
  },
  EDIT: {
    human: 'Edit support',
    action: 'EDIT',
  },
  DELETE: {
    human: 'Delete support record',
    action: 'DELETE',
  },
};

@Component({
  selector: 'support-options-menu-kebab',
  templateUrl: './support-options-menu-kebab.component.html',
  styleUrls: ['./support-options-menu-kebab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class SupportOptionsMenuKebabComponent implements OnInit {
  constructor (
    private modalService: ModalsService,
    private apiService: ApiService,
  ) {}

  @Input() input: { row: IRowData[]; column: IColumn; dynamicComponentInputData?: any };
  @Output() output = new EventEmitter();

  private supportSub: Unsubscribable;
  private schoolId: string;
  private rowMeta: any;
  private rowData: string;

  public options: IDropdownOption[];
  public overlapTrigger: boolean = true;

  public ngOnInit (): void {
    const { row, dynamicComponentInputData: { schoolId } } = this.input;

    this.schoolId = schoolId;

    if (row && row.length) {
      const { data, meta } = row[0];
      this.rowMeta = JSON.parse(meta);
      this.rowData = data;
      this.options = this._initDropdownOptions();
    }
  }

  private _initDropdownOptions (): IDropdownOption[] {
    const { DUPLICATE, EDIT, DELETE } = SUPPORT_OPTIONS_MENU_KEBAB_OPTIONS;
    const options = [
      { key: DUPLICATE.human, human: DUPLICATE.human },
      { key: EDIT.human, human: EDIT.human },
      { key: DELETE.human, human: DELETE.human },
    ];
    return options;
  }

  patchSupport (mode: TSupportModalMode): void {
    this.supportSub = this.apiService.getSupport(this.rowMeta.data).subscribe(support => {
      const data = {
        mode,
        support,
        schoolId: this.schoolId,
        view: 'SETTINGS-LIST' as TSupportsModalView,
      };
      this.modalService.openSupportModal(data);
    });
  }

  public deleteSupport (): void {
    const modalOptions: ISupportStatusModalComponentData = {
      mode: 'DELETE',
      supportId: this.rowMeta.data,
      supportName: this.rowData,
    };
    this.modalService.openSupportStatusModal(modalOptions);
  }

  public onSelect (e: string): void {
    const { DUPLICATE, EDIT, DELETE } = SUPPORT_OPTIONS_MENU_KEBAB_OPTIONS;
    let action: TSupportModalMode;
    switch (e) {
      case DUPLICATE.human: {
        action = DUPLICATE.action;
        this.patchSupport(action);
        break;
      }
      case EDIT.human: {
        action = EDIT.action;
        this.patchSupport(action);
        break;
      }
      case DELETE.human: {
        action = DELETE.action;
        this.deleteSupport();
        break;
      }
    }
  }
}
