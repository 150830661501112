import { ITooltipData } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.interface';
import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef, Injector, ViewEncapsulation } from '@angular/core';
import { ITooltipParams, ColDef } from '@ag-grid-community/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { NvTooltipComponent } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-tooltip.component';
import { TOOLTIP_DATA } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-tooltip.directive';
import { chunk } from 'lodash';

/**
 * This custom tooltip component for the ag-grid is essentially a wrapper for the
 * design library's nv-tooltip component. (AB)
 */

@Component({
  selector: 'ag-tooltip-component',
  templateUrl: './ag-tooltip.component.html',
  styles: [`
    ag-tooltip-component {
      position: absolute;
      pointer-events: none;
    }
    .ag-tooltip-hiding {
      opacity: 0;
    }
  `],
  encapsulation: ViewEncapsulation.None,
})
export class AgTooltipComponent implements ITooltipAngularComp {
  private tooltipData: ITooltipData;
  private tooltipComponent;

  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

  constructor (public resolver: ComponentFactoryResolver) {}

  agInit (params: { tooltipTemplate: string, tooltipHeader: string, hasTooltipData?: boolean } & ITooltipParams): void {
    const { tooltipTemplate, tooltipHeader, location, value, data, colDef, hasTooltipData } = params;
    this.tooltipData = this.getTooltipData(tooltipTemplate, tooltipHeader, value, location, data, colDef, hasTooltipData);

    // this.tooltipData can be null, so we should only proceed with resolving the NvToolTipComponent if there is a tootip to create
    if (this.tooltipData) {
      const injector = Injector.create({ providers: [{ provide: TOOLTIP_DATA, useValue: this.tooltipData }] });
      const nvTooltipFactory = this.resolver.resolveComponentFactory(NvTooltipComponent);
      this.tooltipComponent = this.entry.createComponent(nvTooltipFactory, 0, injector);
    }
  }

  private getTooltipData (tooltipTemplate: string, tooltipHeader: string, value: any, location: string, data: any, colDef: ColDef, hasTooltipData: boolean): ITooltipData {
    if (!value || value.length === 0) return null;
    // Check (hasTooltipData) to see if the cell is providing the tooltip data
    if (hasTooltipData && !data.tooltipData) return null;
    // If hasTooltipData, overwrite value to get the data from the tooltipData field instead of the actual cell value. Ex: StudentTasksNotesPanelComponent
    if (hasTooltipData && data.tooltipData) value = data.tooltipData;

    let tooltipData: ITooltipData;
    const isHeaderTooltip = location === 'header';

    if(isHeaderTooltip) {
      tooltipData = {
        type: 'sync',
        content: `${value}`,
      }; 
    } else { // Cell Tooltips
      switch (tooltipTemplate) {
        // Simple tooltips can only be strings and cannot have a tooltip header
        case 'SimpleTooltip':
          tooltipData = {
            type: 'sync',
            content: `${value}`,
          };  
          break;
        case 'TableTooltipSingleColumn':
          tooltipData = {
            type: 'sync',
            content: {
              rowData: this[tooltipTemplate](value),
              headers: tooltipHeader ? [tooltipHeader] : null,
            },
          };
          break;
        case 'TableTooltipSingleColumnFromString':
          tooltipData = {
            type: 'sync',
            content: {
              rowData: this[tooltipTemplate](value),
              headers: tooltipHeader ? [tooltipHeader] : null,
            },
          };
          break;
        case 'StackListTooltip': // Example of StackList rowData data: nv-tooltip.data.ts#L18
          tooltipData = {
            type: 'sync',
            content: {
              rowData: value,
            },
        };
          break;
          // To utilize the below tooltip, when processing rowData,
          // add a Tooltip + graphql key field with the tooltip data you desire
        case 'DataSimpleTooltip': {
          const cellSimpleTooltipData = this._getrowDataTooltipData(colDef, data);
          if (!cellSimpleTooltipData) return null;
          tooltipData = {
            type: 'sync',
            content: cellSimpleTooltipData,
          };
          break;
        }
        case 'DataSingleColumnTooltip': {
          const cellColumnTooltipData = this._getrowDataTooltipData(colDef, data);
          if (!cellColumnTooltipData) return null;
          const arrData = cellColumnTooltipData.split(',');
          tooltipData = {
            type: 'sync',
            content: {
              rowData: this[tooltipTemplate](arrData),
              headers: tooltipHeader ? [tooltipHeader] : null,
            },
          };
          break;
        }
        default:
          break;
      }
    }

    return tooltipData;
  }

  // Row data formatting functions. Add re-shaping functions here

  private TableTooltipSingleColumn (data: string[]): string[][] {
    return chunk(data, 1);
  }

  private TableTooltipSingleColumnFromString (data: string) {
    const dataArr = data.split(',');
    return this.TableTooltipSingleColumn(dataArr);
  }

  private DataSingleColumnTooltip (data: string[]): string[][] {
    return chunk(data, 1);
  }

  private _getrowDataTooltipData (colDef, data) {
    const { graphQLKey } = colDef;
    const tooltipData = data[`Tooltip=${graphQLKey}`];
    return tooltipData;
  }
}
