import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { UpdateSupport } from 'Src/ng2/store';
import { BaseModalComponent } from '../../base-modal.component';
import { IBaseModalData } from '../../modals.service';
import { IUpdateSupportParams } from './../../../typings/interfaces/support.interface';
import { SharedEventTrackers } from 'Src/ng2/shared/services/mixpanel/event-trackers/shared-tracking.service';
import { SUPPORT_AND_STUDENT_SUPPORT_ACTIONS } from 'Src/ng2/shared/services/mixpanel/event-interfaces/supports-action';

export interface ISupportStatusModalComponentData extends IBaseModalData {
  mode: string;
  supportId: string;
  supportName: string;
}

@Component({
  selector: 'support-status-modal',
  templateUrl: './support-status-modal.component.html',
  styleUrls: ['./support-status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportStatusModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();

  // Modal Configurations
  public schoolId: string;
  public title: string;
  public confirmationButtonLabel: string;
  public cancelButtonLabel: string;
  public supportName: string;
  public supportId: string;
  public mode: string;

  constructor (
    dialogRef: MatDialogRef<SupportStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISupportStatusModalComponentData,
    private store: Store<any>,
    private sharedEventTrackers: SharedEventTrackers,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { mode, supportId, supportName } = cloneDeep(this.data);
    this.isProfileMode = true;
    this.mode = mode;
    this.supportName = supportName;
    this.supportId = supportId;
    if (this.mode === 'DELETE') {
      this.title = 'Delete support record';
      this.confirmationButtonLabel = 'Delete';
      this.cancelButtonLabel = 'Cancel';
    }
  }

  public ngOnDestroy (): void {
    super.ngOnDestroy();
  }

  public close (): void {
    super.close();
  }

  public confirmAction (mode: string) {
    if (mode === 'DELETE') this.deleteSupport();
  }

  public deleteSupport (): void {
    const patch: IUpdateSupportParams = { status: 'DELETED' };
    // In the support modal, we send a madlib refresh request if the payload includes activity leads.
    // Here, we dont have the context that would be needed to know whether a support deletion would result in
    // a state where the lead should no longer exist in the madlib, so send the refresh request regardless.
    const requiresMadlibRefresh = true;
    this.store.dispatch(new UpdateSupport({ supportId: this.supportId, patch, requiresMadlibRefresh } as any));
    this._trackSupportAction();
    this.close();
  }

  private _trackSupportAction () : void {
    this.sharedEventTrackers.trackSupportOrStudentSupportEvent({ pageName: 'SUPPORT-STATUS-MODAL', action: SUPPORT_AND_STUDENT_SUPPORT_ACTIONS.DELETE_SUPPORT });
  }
}
