import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvProgressBarComponent } from './nv-progress-bar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NvProgressBarComponent],
  exports: [NvProgressBarComponent],
})
export class NvProgressBarModule {}
