import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ITooltipContent } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.interface';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';

@Injectable()
export class SchoolUserModalsDataService {
  constructor (private apiService: ApiService) {}

  public getSchoolUserDetail ({ userId, schoolId }): Observable<any> {
    return this.apiService.getSchoolUserDetail({ userId, schoolId });
  }

  JOB_ROLES_OPTIONS: IDropdownOption[] = [
    {
      key: 'Attendance Staff',
      human: 'Attendance Staff',
    },
    {
      key: 'Business Manager/APO',
      human: 'Business Manager/APO',
    },
    {
      key: 'College Advisor',
      human: 'College Advisor',
    },
    {
      key: 'Data Specialist',
      human: 'Data Specialist',
    },
    {
      key: 'Dean',
      human: 'Dean',
    },
    {
      key: 'Department Head',
      human: 'Department Head',
    },
    {
      key: 'Lead Teacher',
      human: 'Lead Teacher',
    },
    {
      key: 'Other',
      human: 'Other',
    },
    {
      key: 'Paraprofessional',
      human: 'Paraprofessional',
    },
    {
      key: 'Parent Coordinator',
      human: 'Parent Coordinator',
    },
    {
      key: 'Department Head',
      human: 'Department Head',
    },
    {
      key: 'Programmer',
      human: 'Programmer',
    },
    {
      key: 'School Aide',
      human: 'School Aide',
    },
    {
      key: 'Teacher',
      human: 'Teacher',
    },
    {
      key: 'Tech Liaison',
      human: 'Tech Liaison',
    },
  ]

  ACCESS_LEVELS_OPTIONS: IDropdownOption[] = [
    {
      key: 'delegated_school_admin',
      human: 'Delegated Admin',
      tags: ['Can edit all students, create supports & manage users'],
    },
    {
      key: 'edit_all',
      human: 'School Wide Editor',
      tags: ['Can edit all students & create supports'],
    },
    {
      key: 'view_all',
      human: 'School Wide Viewer',
      tags: ['Can view all students & add notes'],
    },
    {
      key: 'edit_caseload',
      human: 'Caseload Editor',
      tags: ['Can edit caseload students & create supports'],
    },
    {
      key: 'view_caseload',
      human: 'Caseload Viewer',
      tags: ['Can view caseload students & add notes'],
    },
    {
      key: 'no_access',
      human: 'No access',
      tags: ['Can’t login'],
    },
  ];

  EMPLOYEE_TYPE_OPTIONS: IDropdownOption [] = [
    { key: 'Yes', human: 'Yes' }, { key: 'No', human: 'No' },
  ]

  // STATIC TOOLTIP DATA
  TOOLTIP_DATA_ACCESS_LEVEL: {
    [key: string]: ITooltipContent;
  } = {
    tableContent: {
      rowData: [
        ['Network and school level', 'Can login, access network views, and enter school portals'],
        ['Network level only', "Can login, access network views, but can't enter school portals"],
        ['No access', 'Cannot login'],
      ],
    },
  };

  // STATIC TOOLTIP DATA
  TOOLTIP_DATA_MANAGE_USERS = 'Allows this user to create new users, and update permissions for existing users';
}
