import { PORTAL_TYPES } from '../../../typings/interfaces/portal.interface';
import { EVENT_TYPES } from '../../../typings/interfaces/mixpanel.interface';

export type TSupportsPages = 'BATCH-ACTION'
| 'STUDENT-PROFILE'
| 'SETTINGS-LIST'
| 'SUPPORT-STUDENT-ROSTER'
| 'ATTENDANCE-LOGS'
| 'ATTENDANCE-FORM'
| 'STUDENT-SUPPORT-MODAL'
| 'SUPPORT-MODAL'
| 'SUPPORT-STATUS-MODAL'
| 'SUPPORT-LIST';

export enum SUPPORT_ATTENDANCE_ACTIONS {
  // eslint-disable-next-line no-unused-vars
  VIEW_ATTENDANCE_LOGS = 'viewed support attendance logs list of days',
  // eslint-disable-next-line no-unused-vars
  ALL_PRESENT = 'marked all students present',
  // eslint-disable-next-line no-unused-vars
  SESSION_NOT_HELD = 'selected session not held',
  // eslint-disable-next-line no-unused-vars
  REVERT_TO_SESSION_HELD = 'selected revert to session held',
  // eslint-disable-next-line no-unused-vars
  VIEW_ATTENDANCE_FORM = 'viewed single day individual attendance form',
  // eslint-disable-next-line no-unused-vars
  INDIVIDUAL_ATTENDANCE = 'single student attendance action',
  // eslint-disable-next-line no-unused-vars
  BATCH_ATTENDANCE = 'batch student attendance action',
}

export enum SUPPORT_AND_STUDENT_SUPPORT_ACTIONS {
  // eslint-disable-next-line no-unused-vars
  UPDATE_SUPPORT = 'update support',
  // eslint-disable-next-line no-unused-vars
  UPDATE_STUDENT_SUPPORT = 'update student support dates',
  // eslint-disable-next-line no-unused-vars
  MARK_COMPLETE_STUDENT_SUPPORT = 'mark student support complete',
  // eslint-disable-next-line no-unused-vars
  DUPLICATE_SUPPORT = 'duplicate existing support',
  // eslint-disable-next-line no-unused-vars
  DELETE_SUPPORT = 'delete support',
  // eslint-disable-next-line no-unused-vars
  DELETE_STUDENT_SUPPORT = 'delete student support',
}

export type TAllSupportActions = typeof SUPPORT_ATTENDANCE_ACTIONS[keyof typeof SUPPORT_ATTENDANCE_ACTIONS] | typeof SUPPORT_AND_STUDENT_SUPPORT_ACTIONS[keyof typeof SUPPORT_AND_STUDENT_SUPPORT_ACTIONS];

export interface ISupportsMetadata {
  action: TAllSupportActions | EVENT_TYPES, // Create, Delete, Delete support, etc
  view: TSupportsPages,
  portal: keyof typeof PORTAL_TYPES;
  categories?: string[];
};

export type TFormatSupportEventArgs = Pick<ISupportsMetadata, 'view' | 'portal' | 'categories'>;
