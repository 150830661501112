import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../shared/services/api-service/api-service';
import { ImUser } from '../../../shared/services/im-models/im-user';
import { TValidPartnerTypes } from '../../../shared/typings/interfaces/partner.interface';
import { ITask } from '../../../shared/typings/interfaces/task.interface';
import { IUser } from '../../../shared/typings/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class StudentTasksNotesService {
  constructor (
    public apiService: ApiService,
    private imUser: ImUser,
  ) { }

  public getPaginatedRowData (data: any): Observable<any> {
    const {
      studentId,
      schoolId,
      partnerType,
      collection,
      calcValueField,
      userSelectedFilters,
      page,
      limit,
    } = data;

    const dataFields = this._getDataFields(collection);
    const calcValueFieldPart = calcValueField ? `calcValueField: "${calcValueField}"` : '';
    const userSelectedFiltersPart = userSelectedFilters ? `userSelectedFilters: "${userSelectedFilters}"` : '';

    const query = `{
      PaginatedData(
        studentId: "${studentId}"
        schoolId: "${schoolId}"
        partnerType: "${partnerType}"
        page: "${page}"
        limit: "${limit}"
        collection: "${collection}"
        ${calcValueFieldPart}
        ${userSelectedFiltersPart}
        ) {
        data {
         ${dataFields}
        }
        items {
          begin
          end
          limit
          total
        }
        pages {
          current
          hasNext
          hasPrev
          next
          prev
          total
        }
      }
    }`;

    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getPaginatedDataForStudent(payload).pipe(
      map((res: any) => res.data.PaginatedData),
    );
  }

  public getActivitiesRowData (data: any, options?: any): Observable<any> {
    const { studentId, schoolId, partnerType } = data;
    const whereOptions = {
      studentId,
      historyType: { $ne: null },
    };
    const queryOptions = {
      ...options,
      where: JSON.stringify(whereOptions),
    };
    return this.apiService.getPaginatedDocLogs(partnerType, schoolId, queryOptions);
  }

  private _getDataFields (collection: string): string {
    let fields = '';
    switch (collection) {
      case 'tasks':
        fields = `
          _id
          description
          categories
          assignee {
            userId
            firstName
            lastName
          }
          createdAt
          createdBy {
            userId
            firstName
            lastName
          }
          resolvedBy {
            userId
            firstName
            lastName   
          }
          resolvedAt
          status
          comment
          student {
            lastFirst
          }
        `;
        break;
      case 'notes':
        fields = `
          _id
          schoolId
          shelterId
          createdBy {
            userId
            firstName
            lastName
            gafeEmail
          }
          dateCreated
          lastEditedAt
          status
          attachments {
            student {
              studentId
              lastFirst
            }
            shelterStudent {
              caresId
              firstLast
            }
          }
          body
          categories
          contextPartnerType
          ecfik {
            logDate
            type
            status
            needs
            serviceReferral
          }
        `;
        break;
      case 'doc_logs':
        fields = `
          _id
          schoolId
          shelterId
          createdBy {
            userId
            firstName
            lastName
            gafeEmail
          }
          createdAt
          tags
          description
          tooltipData
        `;
        break;
    }

    return fields;
  }

  public getEditTasksPermissions (user: IUser, partnerType: TValidPartnerTypes, task: ITask): boolean {
    // Cluster users cannot mark tasks complete
    const isClusterUser = this.imUser.isClusterUser(user, partnerType) && this.imUser.isActive(user, partnerType);
    // The school user who created the task
    const userCreateTask = task.createdBy.userId === user._id;
    // Any school admin or delegated school admin, even if they did not create the task
    const isTaskEditor = this.imUser.isTaskEditor(user);
    // Edits are only allowed for todo tasks
    const isActiveTask = task.status === 'ACTIVE';

    const canEdit = (userCreateTask && !isClusterUser && isActiveTask) || (isTaskEditor && !isClusterUser && isActiveTask);
    return canEdit;
  }
}
